
import { defineComponent, onMounted } from "vue";
import EntityList from "@/components/association/widgets/list/EntityList.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "entity-lists",
  components: {
    EntityList,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Lists", ["Widgets"]);

      MenuComponent.reinitialization();
    });
  },
  data() {
    return {};
  },
  methods: {},
});
